import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import Image from 'src/components/atoms/image';
import config from 'src/config';
import { useAppData } from 'src/helpers/use';
import { getAssetUrl } from 'src/helpers/utils';

import FooterStyle from './style';

import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';

const FooterCpd = () => {
  const { t } = useTranslation('common');
  const { appData } = useAppData();
  const phoneNumberAndOfficeHours = appData?.footer?.phone_number_and_office_hours;

  const LINE_URL = 'https://line.me/R/ti/p/%40skilllaneCPD';

  const ContactUs = () => (
    <Box className="footer-section">
      <Typography variant="h3" sx={{ mb: 1, width: '100%' }}>
        {t('home.footer.contact_us')}
      </Typography>
      <a href={`tel:${phoneNumberAndOfficeHours?.phone}`} target="_blank" className="icon-text" rel="noreferrer">
        <FontAwesomeIcon style={{ fontSize: '18px' }} icon="fa-regular fa-phone" /> {phoneNumberAndOfficeHours?.phone} (
        {phoneNumberAndOfficeHours?.phone_hours})
      </a>
      <Box my={1}>
        <Divider />
      </Box>
      <a href={LINE_URL} target="_blank" className="icon-text" rel="noreferrer">
        <Image
          style={{ width: 18, height: 18 }}
          alt="line-icon"
          src={getAssetUrl('cpd-landing/icon-line.png')}
          srcSet={`${getAssetUrl('cpd-landing/icon-line.png')}, ${getAssetUrl('cpd-landing/icon-line@2x.png')} 2x`}
          srcSetWebp={`${getAssetUrl('cpd-landing/icon-line.webp')}, ${getAssetUrl(
            'cpd-landing/icon-line@2x.webp',
          )} 2x`}
        />
        @SkillLaneCPD
      </a>
      <a href="mailto:cpd.info@SkillLane.com" target="_blank" className="icon-text" rel="noreferrer">
        <FontAwesomeIcon style={{ fontSize: '18px' }} icon="fa-regular fa-envelope" /> cpd.info@SkillLane.com
      </a>
      <span className="social-hours">({phoneNumberAndOfficeHours?.social_hours})</span>
    </Box>
  );

  const Policy = () => (
    <Box className="footer-bottom">
      <Box className="copyright">
        <Typography>SkillLane © {new Date().getFullYear()} SkillLane.com All rights reserved. </Typography>
        <Typography href="/cpd/cpd-privacy-policy" target="_blank" className="policy" component="a" fontFamily="Lanna">
          {t('home.footer.policy')}
        </Typography>
        <FontAwesomeIcon style={{ fontSize: '3px' }} className="user-agreement" icon="fa-solid fa-circle" />
        <Typography
          href="/cpd/cpd-terms"
          target="_blank"
          component="a"
          className="user-agreement link"
          fontFamily="Lanna"
        >
          {t('home.footer.terms_and_condition')}
        </Typography>
        <FontAwesomeIcon style={{ fontSize: '3px' }} className="user-agreement" icon="fa-solid fa-circle" />
        <Typography
          href="/privacy-notice"
          target="_blank"
          component="a"
          className="user-agreement link"
          fontFamily="Lanna"
        >
          {t('home.footer.privacy_notice')}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <FooterStyle>
      <Divider />
      <Box>
        <Grid container spacing={2} className="footer-container">
          <Grid item xs={12} sm={6} lg={4} xl={4} sx={{ mt: { md: '4px' }, pr: '40px' }}>
            <Box className="footer-section first">
              <Box className="overview">
                <Image
                  className="logo-image"
                  alt={t('cpd_landing.overview.title')}
                  src={`${config.IMAGE_CDN_HOST}/assets/cpd-landing/LogoCPD.svg`}
                  errorImage={`${config.IMAGE_CDN_HOST}assets/cpd-landing/LogoCPD.svg`}
                />
                <Box style={{ fontWeight: 'bold', fontFamily: 'Heavent' }}>{t('cpd_landing.overview.title')}</Box>
                <Box>{t('cpd_landing.overview.description')}</Box>
              </Box>
            </Box>
          </Grid>
          <Grid container className="footer-list" item xs={12} sm={6} lg={8} xl={8}>
            <Grid item xs={12} sm={6} lg={3} xl={3} />
            <Grid item xs={12} sm={6} lg={3} xl={3} />
            <Grid className="contact-us" item xs={12} sm={12} lg={6} xl={6}>
              <ContactUs />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Policy />
    </FooterStyle>
  );
};

export default FooterCpd;
