import dynamic from 'next/dynamic';
import { ReactNode, useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import Cookies from 'universal-cookie';

import MainStyle from './style';

import FooterCpd from '@components/organisms/cpd/footerCpd';
import { CookiePolicyState } from 'src/helpers/constants';
import { useAppData, useProfile, useWindowSize } from 'src/helpers/use';

const Header = dynamic(import('src/components/organisms/header'));
const Footer = dynamic(import('src/components/organisms/footer'));
const Coupon = dynamic(import('src/components/molecules/coupon'));
const ScrollTop = dynamic(import('src/components/atoms/scrollTop'));
const CookiePolicy = dynamic(import('src/components/molecules/cookiePolicy'));
const CookiePolicyTopBar = dynamic(import('@components/molecules/cookiePolicyTopBar'));

type Props = {
  children?: ReactNode;
  coupon?: true;
  scrollToTop?: true;
  hideHeaderOnScroll?: true;
  scrollBottomDesktop?: number;
  scrollBottomMobile?: number;
  hideDownloadApp?: boolean;
  isFooterCpd?: boolean;
};

const Main = ({
  children,
  coupon,
  scrollToTop,
  hideHeaderOnScroll,
  scrollBottomDesktop = 60,
  scrollBottomMobile = 20,
  hideDownloadApp,
  isFooterCpd = false,
}: Props) => {
  const { t } = useTranslation();
  const { appData } = useAppData();
  const { profile, isLoading } = useProfile();
  const { enqueueSnackbar } = useSnackbar();
  const [cookiePolicyState, setCookiePolicyState] = useState<CookiePolicyState | null>();
  const { width = 0 } = useWindowSize();
  const theme = useTheme();

  const isShowCookiePolicyFooter = cookiePolicyState === CookiePolicyState.NotAccept;
  const isShowCookiePolicyModal = cookiePolicyState === CookiePolicyState.Pending;

  const isShowCoupon = (() => {
    if (!coupon) return false;
    if (!appData?.couponNewUser?.enable) return false;
    if (isShowCookiePolicyFooter || isShowCookiePolicyModal) return false;
    if (!profile && !isLoading) return true;
    return !!(profile && profile.is_eligible_for_coupons_new_user);
  })();

  const checkIsSessionExpired = () => {
    const cookies = new Cookies();
    if (cookies.get('session_expired')) {
      enqueueSnackbar({ message: t('alert.login_or_register.title'), severity: 'error' });
      cookies.remove('session_expired', { path: '/' });
    }
  };

  useEffect(() => {
    checkIsSessionExpired();
  }, []);

  const onCookiePolicyStateChange = (state: CookiePolicyState) => {
    setCookiePolicyState(state);
  };

  const getScrollToTopBottom = () => {
    let cookieFooterHeight = 0;
    let couponHeight = 0;
    let defaultBottom = scrollBottomDesktop;

    if (width > theme.breakpoints.values.lg) {
      cookieFooterHeight = 150;
      couponHeight = 90;
    } else if (width > theme.breakpoints.values.sm) {
      cookieFooterHeight = 210;
      defaultBottom = scrollBottomMobile + 65;
    } else {
      cookieFooterHeight = 260;
      defaultBottom = scrollBottomMobile;
    }
    if (isShowCookiePolicyModal) return defaultBottom;
    if (isShowCookiePolicyFooter) return cookieFooterHeight + 20;
    if (isShowCoupon) return couponHeight + 20;
    return defaultBottom;
  };

  return (
    <MainStyle>
      <CookiePolicyTopBar onStateChange={onCookiePolicyStateChange} />
      <Header hideHeaderOnScroll={hideHeaderOnScroll} />
      <Coupon isShowCoupon={isShowCoupon} />
      <CookiePolicy onStateChange={onCookiePolicyStateChange} />
      <div>{children}</div>
      {!isFooterCpd ? <Footer hideDownloadApp={hideDownloadApp} /> : <FooterCpd />}
      {scrollToTop && <ScrollTop bottom={getScrollToTopBottom()} />}
    </MainStyle>
  );
};

export default Main;
